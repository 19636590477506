import {DefaultWorkup, WorkupAnswer, WorkupInProgressInfo} from '@matchsource/api-generated/subject';
import {WorkupAnswerResponseDTO, SourceTypeLiteral, FormCode} from './workup.interfaces';
import {WorkupAnswerModel} from './workup.model';
import {DefaultValues, FormValues} from '@matchsource/models/workup';
import {removeUiFieldsFromWorkup} from './utils';

export const workupAnswersSerializer = {
  toDTO: ({
    completed,
    recipientGuid,
    sourceGuid,
    sourceType,
    values,
    gamidaOmisirgeOrderInd,
    formCode,
    formVersion,
  }: WorkupAnswerModel): WorkupAnswer => {
    return {
      completed,
      recipientGuid,
      sourceGuid,
      formCode,
      formVersion,
      gamidaOmisirgeOrderInd,
      sourceType: sourceType as SourceTypeLiteral,
      values: removeUiFieldsFromWorkup(values),
    };
  },
  fromDTO: ({
    completed,
    createDate,
    id,
    sourceId,
    sourceType,
    formCode,
  }: WorkupInProgressInfo): WorkupAnswerResponseDTO => {
    return {
      completed,
      createDate,
      id,
      sourceId,
      sourceType,
      formCode: formCode as FormCode,
    };
  },
  defaultAnswerFromDTO: ({values}: DefaultWorkup): DefaultValues => ({values: values as unknown as FormValues}),
};

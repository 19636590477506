import {MapTo} from '@matchsource/api-utils';
import {DefaultTcDonorWorkupModel} from '@matchsource/models/donor-workup';
import {BusinessPartyDefaults} from '@matchsource/api-generated/subject';

export const mapDefaultTcWorkupDtoToModel: MapTo<BusinessPartyDefaults, DefaultTcDonorWorkupModel | null> = (
  input: BusinessPartyDefaults
): DefaultTcDonorWorkupModel | null => {
  if (!input) {
    return null;
  }

  return {
    psamRequirePsams: input.psamRequirePsams,
    psamRequireSecondSetPsams: input.psamRequireSecondSetPsams,
    psamMlAcd: input.psamMlAcd,
    psamMlEdta: input.psamMlEdta,
    psamMlHep: input.psamMlHep,
    psamMlClot: input.psamMlClot,
    psamShipCenterName: input.psamShipCenterName,
    psamShipAttnName: input.psamShipAttnName,
    psamShipAddrLine1: input.psamShipAddrLine1,
    psamShipAddrLine2: input.psamShipAddrLine2,
    psamShipAddrLine3: input.psamShipAddrLine3,
    psamShipCity: input.psamShipCity,
    psamShipStateProvince: input.psamShipStateProvince,
    psamShipCountry: input.psamShipCountry,
    psamShipZipCode: input.psamShipZipCode,
    psamShipTelephone: input.psamShipTelephone,
    psamShipEmail: input.psamShipEmail,
    psamMlAcdSecond: input.psamMlAcdSecond,
    psamMlEdtaSecond: input.psamMlEdtaSecond,
    psamMlHepSecond: input.psamMlHepSecond,
    psamMlClotSecond: input.psamMlClotSecond,
    psamShipCenterNameSecond: input.psamShipCenterNameSecond,
    psamShipAttnNameSecond: input.psamShipAttnNameSecond,
    psamShipAddrLine1Second: input.psamShipAddrLine1Second,
    psamShipAddrLine2Second: input.psamShipAddrLine2Second,
    psamShipAddrLine3Second: input.psamShipAddrLine3Second,
    psamShipCitySecond: input.psamShipCitySecond,
    psamShipStateProvinceSecond: input.psamShipStateProvinceSecond,
    psamShipCountrySecond: input.psamShipCountrySecond,
    psamShipZipCodeSecond: input.psamShipZipCodeSecond,
    psamShipTelephoneSecond: input.psamShipTelephoneSecond,
    psamShipEmailSecond: input.psamShipEmailSecond,

    dcsamDay1Acd: input.dcsamDay1Acd,
    dcsamDay1Edta: input.dcsamDay1Edta,
    dcsamDay1Hep: input.dcsamDay1Hep,
    dcsamDay1Clot: input.dcsamDay1Clot,
    dcsamDay2Acd: input.dcsamDay2Acd,
    dcsamDay2Edta: input.dcsamDay2Edta,
    dcsamDay2Hep: input.dcsamDay2Hep,
    dcsamDay2Clot: input.dcsamDay2Clot,

    prodDay1Acd: input.prodDay1Acd,
    prodDay1Edta: input.prodDay1Edta,
    prodDay1Hep: input.prodDay1Hep,
    prodDay1Clot: input.prodDay1Clot,
    prodDay2Acd: input.prodDay2Acd,
    prodDay2Edta: input.prodDay2Edta,
    prodDay2Hep: input.prodDay2Hep,
    prodDay2Clot: input.prodDay2Clot,

    dcsamWillBeSentWithProd: input.dcsamWillBeSentWithProd,
    dcsamCenterName: input.dcsamCenterName,
    dcsamAttnName: input.dcsamAttnName,
    dcsamAddrLine1: input.dcsamAddrLine1,
    dcsamAddrLine2: input.dcsamAddrLine2,
    dcsamAddrLine3: input.dcsamAddrLine3,
    dcsamCity: input.dcsamCity,
    dcsamStateProv: input.dcsamStateProv,
    dcsamZipPostalCode: input.dcsamZipPostalCode,
    dcsamCountry: input.dcsamCountry,
    dcsamPhone: input.dcsamPhone,
    dcsamEmail: input.dcsamEmail,

    dcsamRequireSecondSet: input.dcsamRequireSecondSet,

    dcsamDay1AcdSecond: input.dcsamDay1AcdSecond,
    dcsamDay1EdtaSecond: input.dcsamDay1EdtaSecond,
    dcsamDay1HepSecond: input.dcsamDay1HepSecond,
    dcsamDay1ClotSecond: input.dcsamDay1ClotSecond,
    dcsamDay2AcdSecond: input.dcsamDay2AcdSecond,
    dcsamDay2EdtaSecond: input.dcsamDay2EdtaSecond,
    dcsamDay2HepSecond: input.dcsamDay2HepSecond,
    dcsamDay2ClotSecond: input.dcsamDay2ClotSecond,

    prodDay1AcdSecond: input.prodDay1AcdSecond,
    prodDay1EdtaSecond: input.prodDay1EdtaSecond,
    prodDay1HepSecond: input.prodDay1HepSecond,
    prodDay1ClotSecond: input.prodDay1ClotSecond,
    prodDay2AcdSecond: input.prodDay2AcdSecond,
    prodDay2EdtaSecond: input.prodDay2EdtaSecond,
    prodDay2HepSecond: input.prodDay2HepSecond,
    prodDay2ClotSecond: input.prodDay2ClotSecond,

    dcsamDeliveryCenterName: input.dcsamDeliveryCenterName,
    dcsamDeliveryAttnName: input.dcsamDeliveryAttnName,
    dcsamDeliveryAddrLine1: input.dcsamDeliveryAddrLine1,
    dcsamDeliveryAddrLine2: input.dcsamDeliveryAddrLine2,
    dcsamDeliveryAddrLine3: input.dcsamDeliveryAddrLine3,
    dcsamDeliveryCity: input.dcsamDeliveryCity,
    dcsamDeliveryStateProv: input.dcsamDeliveryStateProv,
    dcsamDeliveryZipCode: input.dcsamDeliveryZipCode,
    dcsamDeliveryCountry: input.dcsamDeliveryCountry,
    dcsamDeliveryEmail: input.dcsamDeliveryEmail,
    dcsamDeliveryPhone: input.dcsamDeliveryPhone,

    anticoagulant: input.anticoagulant,
    amtRatio: input.amtRatio,
    nuclPerKg: input.nuclPerKg,

    prodDeliveryTcName: input.prodDeliveryTcName,
    prodDeliveryAttnName: input.prodDeliveryAttnName,
    prodDeliveryAddrLine1: input.prodDeliveryAddrLine1,
    prodDeliveryAddrLine2: input.prodDeliveryAddrLine2,
    prodDeliveryAddrLine3: input.prodDeliveryAddrLine3,
    prodDeliveryCity: input.prodDeliveryCity,
    prodDeliveryPhone: input.prodDeliveryPhone,
    prodDeliveryEmail: input.prodDeliveryEmail,
    prodDeliveryStateProvince: input.prodDeliveryStateProvince,
    prodDeliveryZip: input.prodDeliveryZip,
    prodDeliveryCountry: input.prodDeliveryCountry,
    firstEmergencyContPhn: input.firstEmergencyContPhn,
    firstEmergencyContNme: input.firstEmergencyContNme,
    secondEmergencyContPhn: input.secondEmergencyContPhn,
    secondEmergencyContNme: input.secondEmergencyContNme,
    aphCtrEmail: input.aphCtrEmail,
    aphCtrFax: input.aphCtrFax,
    cd34Cells: input.cd34Cells,
    optionalCommentsText: input.optionalCommentsText,
  };
};

export const mapDefaultTcWorkupModelToDto: MapTo<DefaultTcDonorWorkupModel, BusinessPartyDefaults> = (
  input: DefaultTcDonorWorkupModel,
  tcId: MsApp.Guid
): BusinessPartyDefaults => ({
  bpGuid: tcId,
  psamRequirePsams: input.psamRequirePsams,
  psamRequireSecondSetPsams: input.psamRequireSecondSetPsams,
  psamMlAcd: input.psamMlAcd,
  psamMlEdta: input.psamMlEdta,
  psamMlHep: input.psamMlHep,
  psamMlClot: input.psamMlClot,
  psamShipCenterName: input.psamShipCenterName,
  psamShipAttnName: input.psamShipAttnName,
  psamShipAddrLine1: input.psamShipAddrLine1,
  psamShipAddrLine2: input.psamShipAddrLine2,
  psamShipAddrLine3: input.psamShipAddrLine3,
  psamShipCity: input.psamShipCity,
  psamShipStateProvince: input.psamShipStateProvince,
  psamShipCountry: input.psamShipCountry,
  psamShipZipCode: input.psamShipZipCode,
  psamShipTelephone: input.psamShipTelephone,
  psamShipEmail: input.psamShipEmail,
  psamMlAcdSecond: input.psamMlAcdSecond,
  psamMlEdtaSecond: input.psamMlEdtaSecond,
  psamMlHepSecond: input.psamMlHepSecond,
  psamMlClotSecond: input.psamMlClotSecond,
  psamShipCenterNameSecond: input.psamShipCenterNameSecond,
  psamShipAttnNameSecond: input.psamShipAttnNameSecond,
  psamShipAddrLine1Second: input.psamShipAddrLine1Second,
  psamShipAddrLine2Second: input.psamShipAddrLine2Second,
  psamShipAddrLine3Second: input.psamShipAddrLine3Second,
  psamShipCitySecond: input.psamShipCitySecond,
  psamShipStateProvinceSecond: input.psamShipStateProvinceSecond,
  psamShipCountrySecond: input.psamShipCountrySecond,
  psamShipZipCodeSecond: input.psamShipZipCodeSecond,
  psamShipTelephoneSecond: input.psamShipTelephoneSecond,
  psamShipEmailSecond: input.psamShipEmailSecond,

  dcsamDay1Acd: input.dcsamDay1Acd,
  dcsamDay1Edta: input.dcsamDay1Edta,
  dcsamDay1Hep: input.dcsamDay1Hep,
  dcsamDay1Clot: input.dcsamDay1Clot,
  dcsamDay2Acd: input.dcsamDay2Acd,
  dcsamDay2Edta: input.dcsamDay2Edta,
  dcsamDay2Hep: input.dcsamDay2Hep,
  dcsamDay2Clot: input.dcsamDay2Clot,

  prodDay1Acd: input.prodDay1Acd,
  prodDay1Edta: input.prodDay1Edta,
  prodDay1Hep: input.prodDay1Hep,
  prodDay1Clot: input.prodDay1Clot,
  prodDay2Acd: input.prodDay2Acd,
  prodDay2Edta: input.prodDay2Edta,
  prodDay2Hep: input.prodDay2Hep,
  prodDay2Clot: input.prodDay2Clot,

  dcsamWillBeSentWithProd: input.dcsamWillBeSentWithProd,
  dcsamCenterName: input.dcsamCenterName,
  dcsamAttnName: input.dcsamAttnName,
  dcsamAddrLine1: input.dcsamAddrLine1,
  dcsamAddrLine2: input.dcsamAddrLine2,
  dcsamAddrLine3: input.dcsamAddrLine3,
  dcsamCity: input.dcsamCity,
  dcsamStateProv: input.dcsamStateProv,
  dcsamZipPostalCode: input.dcsamZipPostalCode,
  dcsamCountry: input.dcsamCountry,
  dcsamPhone: input.dcsamPhone,
  dcsamEmail: input.dcsamEmail,

  dcsamRequireSecondSet: input.dcsamRequireSecondSet,

  dcsamDay1AcdSecond: input.dcsamDay1AcdSecond,
  dcsamDay1EdtaSecond: input.dcsamDay1EdtaSecond,
  dcsamDay1HepSecond: input.dcsamDay1HepSecond,
  dcsamDay1ClotSecond: input.dcsamDay1ClotSecond,
  dcsamDay2AcdSecond: input.dcsamDay2AcdSecond,
  dcsamDay2EdtaSecond: input.dcsamDay2EdtaSecond,
  dcsamDay2HepSecond: input.dcsamDay2HepSecond,
  dcsamDay2ClotSecond: input.dcsamDay2ClotSecond,

  prodDay1AcdSecond: input.prodDay1AcdSecond,
  prodDay1EdtaSecond: input.prodDay1EdtaSecond,
  prodDay1HepSecond: input.prodDay1HepSecond,
  prodDay1ClotSecond: input.prodDay1ClotSecond,
  prodDay2AcdSecond: input.prodDay2AcdSecond,
  prodDay2EdtaSecond: input.prodDay2EdtaSecond,
  prodDay2HepSecond: input.prodDay2HepSecond,
  prodDay2ClotSecond: input.prodDay2ClotSecond,

  dcsamDeliveryCenterName: input.dcsamDeliveryCenterName,
  dcsamDeliveryAttnName: input.dcsamDeliveryAttnName,
  dcsamDeliveryAddrLine1: input.dcsamDeliveryAddrLine1,
  dcsamDeliveryAddrLine2: input.dcsamDeliveryAddrLine2,
  dcsamDeliveryAddrLine3: input.dcsamDeliveryAddrLine3,
  dcsamDeliveryCity: input.dcsamDeliveryCity,
  dcsamDeliveryStateProv: input.dcsamDeliveryStateProv,
  dcsamDeliveryZipCode: input.dcsamDeliveryZipCode,
  dcsamDeliveryCountry: input.dcsamDeliveryCountry,
  dcsamDeliveryEmail: input.dcsamDeliveryEmail,
  dcsamDeliveryPhone: input.dcsamDeliveryPhone,

  anticoagulant: input.anticoagulant,
  amtRatio: input.amtRatio,
  nuclPerKg: input.nuclPerKg,

  prodDeliveryTcName: input.prodDeliveryTcName,
  prodDeliveryAttnName: input.prodDeliveryAttnName,
  prodDeliveryAddrLine1: input.prodDeliveryAddrLine1,
  prodDeliveryAddrLine2: input.prodDeliveryAddrLine2,
  prodDeliveryAddrLine3: input.prodDeliveryAddrLine3,
  prodDeliveryCity: input.prodDeliveryCity,
  prodDeliveryPhone: input.prodDeliveryPhone,
  prodDeliveryEmail: input.prodDeliveryEmail,
  prodDeliveryStateProvince: input.prodDeliveryStateProvince,
  prodDeliveryZip: input.prodDeliveryZip,
  prodDeliveryCountry: input.prodDeliveryCountry,
  firstEmergencyContPhn: input.firstEmergencyContPhn,
  firstEmergencyContNme: input.firstEmergencyContNme,
  secondEmergencyContPhn: input.secondEmergencyContPhn,
  secondEmergencyContNme: input.secondEmergencyContNme,
  aphCtrEmail: input.aphCtrEmail,
  aphCtrFax: input.aphCtrFax,
  cd34Cells: input.cd34Cells,
  optionalCommentsText: input.optionalCommentsText,
});

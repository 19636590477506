import omit from 'lodash-es/omit';
import {
  FormCode,
  WORKUP_UI_FIELDS,
  WorkupInProgressModel,
  WorkupItems,
  WorkupStructureModel,
} from '@matchsource/models/workup';
import {MapTo} from '@matchsource/api-utils';
import {SubsequentRequestReason, WorkupInProgressInfo} from '@matchsource/api-generated/subject';
import {SubsequentReasonModel} from '@matchsource/models/nomenclature';

export const removeUiFieldsFromWorkup = (fields: MsApp.Dictionary) => omit(fields, WORKUP_UI_FIELDS);

export const mapWorkupInProgresDtoToModel: MapTo<WorkupInProgressInfo, WorkupInProgressModel> = ({
  completed,
  createDate,
  id,
  sourceId,
  sourceType,
  gamidaOmisirgeOrderInd,
  formCode,
}) => ({
  id,
  sourceId,
  createDate,
  sourceType,
  gamidaOmisirgeOrderInd,
  isCompleted: completed,
  formCode: formCode as FormCode,
});

export const mapSubsequentReasonDtoToModel: MapTo<SubsequentRequestReason, SubsequentReasonModel> = ({
  code,
  description,
}) => ({
  code,
  description,
});

export const changeOthSpecifyFieldMaxLength = (workupStructure: WorkupStructureModel) => {
  const NEW_MAX_LENGTH = 30;
  const field = findOthSpecifyField(workupStructure.items, ['recip_tab', 'recip_info', 'diagnosis', 'oth_specify']);

  if (field) {
    field.maxLength = NEW_MAX_LENGTH;
  }
};

export const findOthSpecifyField = (items: WorkupItems[], codeHierarchy: string[]): WorkupItems => {
  const currentCode = codeHierarchy[0];

  for (const item of items) {
    if (item.code === currentCode) {
      if (codeHierarchy.length === 1) {
        return item;
      } else {
        codeHierarchy.shift();

        return findOthSpecifyField(item.items, codeHierarchy);
      }
    }
  }
};

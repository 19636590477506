import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {StructureType, WorkupAnswerResponseDTO} from './workup.interfaces';
import {mapWorkupStructureDtoToModel} from './workup-structure.serializer';
import {WorkupAnswerModel} from './workup.model';
import {workupAnswersSerializer} from './workup-answers.serializer';
import {
  DefaultValues,
  WorkupInProgressModel,
  WorkupStructureModel,
  FormValues,
  InquiryCode,
} from '@matchsource/models/workup';
import {
  CopyWorkupControllerService,
  WorkupControllerService,
  WorkupAnswer,
  NomenclaturesControllerService,
} from '@matchsource/api-generated/subject';
import {changeOthSpecifyFieldMaxLength, mapSubsequentReasonDtoToModel, mapWorkupInProgresDtoToModel} from './utils';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {SubsequentReasonModel} from '@matchsource/models/nomenclature';
import {FullDonorWorkupModel} from '@matchsource/models/donor-workup';

@Injectable({
  providedIn: 'root',
})
export class WorkupApiService {
  constructor(
    private readonly copyWorkupController: CopyWorkupControllerService,
    private readonly workupControllerService: WorkupControllerService,
    private readonly nomenclaturesControllerService: NomenclaturesControllerService
  ) {}

  getWorkupStructure(
    inquiryCode: InquiryCode,
    version?: string,
    structureType?: StructureType
  ): Observable<WorkupStructureModel> {
    return this.workupControllerService.loadWorkupFormStructure1({inquiryCode, version, structureType}).pipe(
      map(code => mapWorkupStructureDtoToModel(code)),
      tap(workupStructure => changeOthSpecifyFieldMaxLength(workupStructure))
    );
  }

  saveWorkupInProgress(workup: WorkupAnswerModel): Observable<WorkupAnswerResponseDTO> {
    return this.workupControllerService
      .saveWorkupAnswerInProgress({body: workupAnswersSerializer.toDTO(workup)})
      .pipe(map(value => workupAnswersSerializer.fromDTO(value)));
  }

  saveDefaultWorkup(tcId: MsApp.Guid, version: string, workup: FormValues): Observable<void> {
    return this.workupControllerService.saveDefaultWorkupAnswer({
      bpGuid: tcId,
      version,
      body: {values: workup} as {[key: string]: unknown},
    });
  }

  updateWorkupInProgress(workup: WorkupAnswerModel, workupId: number): Observable<void> {
    return this.workupControllerService.updateWorkupAnswerInProgress({
      id: workupId,
      body: workupAnswersSerializer.toDTO(workup),
    });
  }

  getDefaultWorkupData(tcId: MsApp.Guid, version: string): Observable<DefaultValues> {
    return this.workupControllerService
      .loadDefaultWorkupAnswer({bpGuid: tcId, version})
      .pipe(map(value => workupAnswersSerializer.defaultAnswerFromDTO(value)));
  }

  getWorkupInProgress(workupId: number) {
    return this.workupControllerService.loadWorkupAnswerInProgressById({id: workupId});
  }

  getWorkupAnswers(orderId: MsApp.Guid, isOriginalForm = false): Observable<WorkupAnswer> {
    return this.workupControllerService.loadWorkupAnswer({orderGuid: orderId, isOriginalForm});
  }

  saveDefaultPatientWorkup(workupAnswer: WorkupAnswerModel) {
    return this.copyWorkupController.saveCopyOfWorkupForm({
      body: workupAnswersSerializer.toDTO(workupAnswer),
    });
  }

  getDefaultPatientWorkup(patientGuid: MsApp.Guid, sourceType: string): Observable<any> {
    return this.copyWorkupController.getCopyOfWorkupFormByPatientGuid({
      patientGuid,
      sourceType: sourceType.toUpperCase() as 'DONOR' | 'CORD',
    });
  }

  getWorkupsInProgress(patientGuid: MsApp.Guid): Observable<WorkupInProgressModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingOrderDetails);

    return this.workupControllerService
      .loadWorkupAnswerInProgress(
        {
          patientGuid,
        },
        {context: context()}
      )
      .pipe(map(workups => workups.map(workup => mapWorkupInProgresDtoToModel(workup))));
  }

  removeWorkupInProgress(workupId: number): Observable<void> {
    return this.workupControllerService.removeWorkupAnswerInProgress({
      workupId,
    });
  }

  getSubsequentReasons(): Observable<SubsequentReasonModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSubjectData);

    return this.nomenclaturesControllerService
      .getSubsequentRequestReasons(null, {context: context()})
      .pipe(map(reasons => reasons.map(reason => mapSubsequentReasonDtoToModel(reason))));
  }

  saveWorkupAnswer(orderGuid: MsApp.Guid, workupAnswer: FullDonorWorkupModel, isOriginal = false): Observable<number> {
    return this.workupControllerService
      .saveWorkupAnswer({orderGuid, isOriginal, body: workupAnswer})
      .pipe(map((id: number) => id));
  }
}

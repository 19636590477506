import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {
  DefaultTcDonorWorkupModel,
  DonorWorkupModel,
  FullDonorWorkupModel,
  DonorWorkupCollection,
} from '@matchsource/models/donor-workup';
import {
  WorkupEnhancedControllerService,
  BusinessPartyDefaults,
  WuDefaultWorkup,
  Workup,
  WorkupInProgressInfo,
  WorkupId,
} from '@matchsource/api-generated/subject';
import {map} from 'rxjs/operators';
import {mapDefaultTcWorkupDtoToModel, mapDefaultTcWorkupModelToDto} from './default-tc-workup.serializer';

import {
  mapDefaultWorkupDtoToModel,
  mapWorkupDtoToFullModel,
  mapWorkupDtoToModel,
  mapWorkupInProgressInfoToModel,
  mapWorkupModelToDefaultWorkupDto,
  mapWorkupModelToDto,
} from './donor-workup.serializer';

import {WorkupInProgressModel} from '@matchsource/models/workup';

export interface DonorWorkupInProgressExtraData {
  patientId: MsApp.Guid;
  tcId: MsApp.Guid;
  sourceId: MsApp.Guid;
  completed: boolean;
  workupId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DonorWorkupApiService {
  constructor(private readonly workupEnhancedController: WorkupEnhancedControllerService) {}

  getDefaultTcWorkup(tcId: MsApp.Guid): Observable<DefaultTcDonorWorkupModel | null> {
    return this.workupEnhancedController
      .loadBusinessPartyDefaults({bpGuid: tcId})
      .pipe(map((dto: BusinessPartyDefaults) => mapDefaultTcWorkupDtoToModel(dto)));
  }

  saveDefaultTcWorkup(tcId: MsApp.Guid, defaultWorkup: DefaultTcDonorWorkupModel): Observable<void> {
    const dto: BusinessPartyDefaults = mapDefaultTcWorkupModelToDto(defaultWorkup, tcId);

    return this.workupEnhancedController.saveBusinessPartyDefaults({body: dto});
  }

  getDefaultPatientWorkup(patientId: MsApp.Guid, tcId: MsApp.Guid): Observable<DonorWorkupModel | null> {
    return this.workupEnhancedController
      .loadPatientDefaults({bpGuid: tcId, subjectGuid: patientId})
      .pipe(map((dto: WuDefaultWorkup) => mapDefaultWorkupDtoToModel(dto)));
  }

  saveDefaultPatientWorkup(patientId: MsApp.Guid, tcId: MsApp.Guid, defaultWorkup: DonorWorkupModel): Observable<void> {
    const dto: BusinessPartyDefaults = mapWorkupModelToDefaultWorkupDto(defaultWorkup, {patientId, tcId});

    return this.workupEnhancedController.savePatientDefaults({body: dto});
  }

  getWorkupInProgress(workupId: number): Observable<DonorWorkupModel> {
    return this.workupEnhancedController
      .loadWorkupInProgressById({id: workupId})
      .pipe(map((dto: Workup) => mapWorkupDtoToModel(dto)));
  }

  getWorkupByOrderGuid(orderId: MsApp.Guid, isOriginalForm = false): Observable<FullDonorWorkupModel> {
    return this.workupEnhancedController
      .getWorkupByOrderGuid({orderGuid: orderId, isOriginalForm})
      .pipe(map((dto: Workup) => mapWorkupDtoToFullModel(dto)));
  }

  getWorkupCollection(orderId: MsApp.Guid): Observable<DonorWorkupCollection> {
    return forkJoin([
      this.workupEnhancedController.getWorkupByOrderGuid({orderGuid: orderId, isOriginalForm: true}),
      this.workupEnhancedController.getWorkupByOrderGuid({orderGuid: orderId, isOriginalForm: false}),
    ]).pipe(
      map(([original, working]) => {
        return {
          originalWorkup: original ? mapWorkupDtoToFullModel(original) : null,
          workingWorkup: working ? mapWorkupDtoToFullModel(working) : null,
        };
      })
    );
  }

  createWorkupInProgress(workup: DonorWorkupModel, metadata: DonorWorkupInProgressExtraData): Observable<number> {
    const workupDto: Workup = mapWorkupModelToDto(workup, metadata);
    return this.workupEnhancedController
      .saveWorkupInProgress({body: workupDto})
      .pipe(map(({id}: WorkupInProgressInfo) => id));
  }

  updateWorkupInProgress(workup: DonorWorkupModel, metadata: DonorWorkupInProgressExtraData): Observable<void> {
    const workupDto: Workup = mapWorkupModelToDto(workup, metadata);
    return this.workupEnhancedController.updateWorkupInProgress({body: workupDto});
  }

  getWorkupsInProgress(patientId: MsApp.Guid): Observable<WorkupInProgressModel[]> {
    return this.workupEnhancedController
      .loadWorkupInProgress({patientGuid: patientId})
      .pipe(map(workups => workups.map(workup => mapWorkupInProgressInfoToModel(workup))));
  }

  removeWorkupInProgress(workupId: number): Observable<void> {
    return this.workupEnhancedController.removeWorkupInProgress({id: workupId});
  }

  saveWorkingWorkup(orderId: MsApp.Guid, workup: FullDonorWorkupModel): Observable<number> {
    return this.workupEnhancedController
      .saveWorkup({orderGuid: orderId, isOriginalForm: false, body: workup})
      .pipe(map(({id}: WorkupId) => id));
  }

  updateWorkingWorkup(orderId: MsApp.Guid, workup: FullDonorWorkupModel): Observable<void> {
    return this.workupEnhancedController.updateWorkingWorkup({orderGuid: orderId, body: workup});
  }
}

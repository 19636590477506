import {MapTo} from '@matchsource/api-utils';
import {WorkupFormNavDTO} from './workup.interfaces';
import {FormNavConfig} from '@matchsource/models/workup';
import omit from 'lodash-es/omit';

export const mapFormNavDtoToModel: MapTo<WorkupFormNavDTO, FormNavConfig> = (input: WorkupFormNavDTO) =>
  input.map((item, idx) => ({
    id: idx,
    ...item,
  }));

export const mapFormNavModelToDto: MapTo<FormNavConfig, WorkupFormNavDTO> = (input: FormNavConfig) =>
  input.map(item => omit(item, ['id']));

import {mapFormNavDtoToModel} from './workup-nav.serializer';
import {FormNavItem, WorkupItems, WorkupStructureModel} from '@matchsource/models/workup';
import {WorkupForm} from '@matchsource/api-generated/subject';

export const mapWorkupStructureDtoToModel = ({
  allowedDefaultIds,
  formVersion,
  items,
  navigations,
}: WorkupForm): WorkupStructureModel => {
  return {
    allowedDefaultIds,
    formVersion,
    items: items as WorkupItems[],
    navigations: mapFormNavDtoToModel(navigations as FormNavItem[]),
  };
};
